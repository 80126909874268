import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import img from '../assets/images/index.jpg'
import { HomeHeader, Banner } from '../components/utils'
import Posts from "../components/posts/Posts"

const IndexPage = ({data}) => {

  return (
  <Layout>
    <SEO title="Home" keywords={['blog', 'travel', 'life']} />
    <HomeHeader img={img}>
      <Banner title="" subtitle="Walk through the World with me...">
      </Banner>
    </HomeHeader>
    <Posts posts={data.allMarkdownRemark.edges}></Posts>
  </Layout>
  )
}

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(
			sort: { order: DESC, fields: [frontmatter___date] }, filter: { frontmatter: {draft: {ne: true}}}) {
			edges {
        node {
          fields{
            slug
            readingTime {
              minutes
            }
          }
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM Do YYYY")
						title
						cover {
							childImageSharp {
								fluid(maxWidth:512) {
									...GatsbyImageSharpFluid
								}
							}
						}
          }
        }
      }
    }
  }
`

export default IndexPage
