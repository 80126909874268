import React from 'react';
import styled from 'styled-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image';

import { FiGlobe } from 'react-icons/fi'
import { FiAperture } from 'react-icons/fi'

import { styles, PostButton } from '../utils'
import img from '../../assets/images/post.jpg'

function PostItem({ title, description, date, link, time, category, cover, children }) {
	return ( 
      <PostItemWrapper>
		<div className='headerImage'>
			<Link to={link} key={title} className="image">
				{ cover ? <ImgWrapper  fluid={cover.childImageSharp.fluid} />
								:	<img src={img} alt="" />
				}
			</Link>
		</div>

		<div className='header'>
			<div><h3>{title} <i>({Math.round(time)} min)</i></h3></div>
			<div className='date'>{date}</div>
		</div>
		<p>{description}</p>
		<ul className="actions">
			<li>
				<Link to={link} className="button">More</Link>
            </li>
		</ul>
        {children}
      </PostItemWrapper>
    )
}

const ImgWrapper = styled(Img)`
		& {
			-moz-transition: -moz-transform 0.2s ease;
			-webkit-transition: -webkit-transform 0.2s ease;
			-ms-transition: -ms-transform 0.2s ease;
			transition: transform 0.2s ease;
		}

		&:hover {
			-moz-transform: scale(1.075);
			-webkit-transform: scale(1.075);
			-ms-transform: scale(1.075);
			transform: scale(1.075);
		}
`

const PostItemWrapper = styled.article`
	.header {
		display: flex; justify-content: space-between;
	}

	.date {
		text-align: left;
		font-style: italic;
	}

/* TODO: Figure how to align left */
	.headerImage {
		justify-content: space-between;
	}

	.category {
		align-content: left;
	}

/* Image */
    .image {
		border-radius: 0.375em;
		border: 0;
		display: inline-block;
		position: relative;
	}

		.image img {
			border-radius: 0.375em;
			display: block;
                    
            width: 10rem; 
            height: auto; 

		}

		.image.left, .image.right {
			max-width: 40%;
		}

			.image.left img, .image.right img {
				width: 100%;
			}

		.image.left {
			float: left;
			padding: 0 1.5em 1em 0;
			top: 0.25em;
		}

		.image.right {
			float: right;
			padding: 0 0 1em 1.5em;
			top: 0.25em;
		}

		.image.fit {
			display: block;
			margin: 0 0 2em 0;
			width: 100%;
		}

			.image.fit img {
				width: 100%;
			}

		.image.main {
			display: block;
			margin: 0 0 3em 0;
			width: 100%;
		}

			.image.main img {
				width: 100%;
			}

	a.image {
		overflow: hidden;
	}

		a.image img {
			-moz-transition: -moz-transform 0.2s ease;
			-webkit-transition: -webkit-transform 0.2s ease;
			-ms-transition: -ms-transform 0.2s ease;
			transition: transform 0.2s ease;
		}

		a.image:hover img {
			-moz-transform: scale(1.075);
			-webkit-transform: scale(1.075);
			-ms-transform: scale(1.075);
			transform: scale(1.075);
		}

/* List */

ol {
		list-style: decimal;
		margin: 0 0 2em 0;
		padding-left: 1.25em;
	}

		ol li {
			padding-left: 0.25em;
		}

	ul {
		list-style: disc;
		margin: 0 0 2em 0;
		padding-left: 1em;
	}

		ul li {
			padding-left: 0.5em;
		}

		ul.alt {
			list-style: none;
			padding-left: 0;
		}

			ul.alt li {
				border-top: solid 1px rgba(210, 215, 217, 0.75);
				padding: 0.5em 0;
			}

				ul.alt li:first-child {
					border-top: 0;
					padding-top: 0;
				}

	dl {
		margin: 0 0 2em 0;
	}

		dl dt {
			display: block;
			font-weight: 600;
			margin: 0 0 1em 0;
		}

		dl dd {
			margin-left: 2em;
		}

/* Actions */

ul.actions {
		display: -moz-flex;
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
		cursor: default;
		list-style: none;
		margin-left: -1em;
		padding-left: 0;
		padding-top: 1.3rem;
	}

		ul.actions li {
			padding: 0 0 0 1em;
			vertical-align: middle;
		}

		ul.actions.special {
			-moz-justify-content: center;
			-webkit-justify-content: center;
			-ms-justify-content: center;
			justify-content: center;
			width: 100%;
			margin-left: 0;
		}

			ul.actions.special li:first-child {
				padding-left: 0;
			}

		ul.actions.stacked {
			-moz-flex-direction: column;
			-webkit-flex-direction: column;
			-ms-flex-direction: column;
			flex-direction: column;
			margin-left: 0;
		}

			ul.actions.stacked li {
				padding: 1.3em 0 0 0;
			}

				ul.actions.stacked li:first-child {
					padding-top: 0;
				}

		ul.actions.fit {
			width: calc(100% + 1em);
		}

			ul.actions.fit li {
				-moz-flex-grow: 1;
				-webkit-flex-grow: 1;
				-ms-flex-grow: 1;
				flex-grow: 1;
				-moz-flex-shrink: 1;
				-webkit-flex-shrink: 1;
				-ms-flex-shrink: 1;
				flex-shrink: 1;
				width: 100%;
			}

				ul.actions.fit li > * {
					width: 100%;
				}

			ul.actions.fit.stacked {
				width: 100%;
			}

`
  
export default PostItem