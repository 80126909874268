import React from 'react'

import styled from 'styled-components'
import { Link, graphql, StaticQuery } from 'gatsby'
import PostItem from './PostItem';

export default function PostList({posts}) {
  return (
		<PostSectionWrapper>
					<PostsWrapper className="posts">
                {
                  posts.map(({ node }, i) => (
                      <PostItem key={i} title={node.frontmatter.title} 
                            description={node.excerpt} 
                            date={node.frontmatter.date} 
														link={node.fields.slug}
														time={node.fields.readingTime.minutes}
														category={node.fields.category}
														tags={node.fields.tags}
														cover={node.frontmatter.cover}>
                      </PostItem>
										))
								}
              </PostsWrapper>
		</PostSectionWrapper>
  );
}

const PostSectionWrapper = styled.section`
	padding: 3rem 3rem 3rem 3rem;
`

const PostsWrapper = styled.div`
  /* Posts */
	
		display: -moz-flex;
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
		-moz-flex-wrap: wrap;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		margin: 0 0 2em -6em;
		width: calc(100% + 6em);

	/* Articles */
	article {
			-moz-flex-grow: 0;
			-webkit-flex-grow: 0;
			-ms-flex-grow: 0;
			flex-grow: 0;
			-moz-flex-shrink: 1;
			-webkit-flex-shrink: 1;
			-ms-flex-shrink: 1;
			flex-shrink: 1;
			margin: 0 0 6em 6em;
			position: relative;
			width: calc(33.3333333333% - 6em);
		}

			article:before {
				background: rgba(210, 215, 217, 0.75);
				content: '';
				display: block;
				height: calc(100% + 6em);
				left: -3em;
				position: absolute;
				top: 0;
				width: 1px;
			}

			article:after {
				background: rgba(210, 215, 217, 0.75);
				bottom: -3em;
				content: '';
				display: block;
				height: 1px;
				position: absolute;
				right: 0;
				width: calc(100% + 6em);
			}

			article > :last-child {
				margin-bottom: 0;
			}

			article .image {
				display: block;
				margin: 0 0 2em 0;
			}

				article .image img {
					display: block;
					width: 100%;
				}


		@media screen and (min-width: 1681px) {

			article:nth-child(3n + 1):before {
				display: none;
			}

			article:nth-child(3n + 1):after {
				width: 100%;
			}

			article:nth-last-child(1), .posts article:nth-last-child(2), .posts article:nth-last-child(3) {
				margin-bottom: 0;
			}

			article:nth-last-child(1):before, .posts article:nth-last-child(2):before, .posts article:nth-last-child(3):before {
					height: 100%;
				}

			article:nth-last-child(1):after, .posts article:nth-last-child(2):after, .posts article:nth-last-child(3):after {
					display: none;
				}

		}

		@media screen and (max-width: 1680px) {

			article {
				width: calc(50% - 6em);
			}

			article:nth-last-child(3) {
					margin-bottom: 6em;
				}

		}

		@media screen and (min-width: 481px) and (max-width: 1680px) {

			article:nth-child(2n + 1):before {
				display: none;
			}

			article:nth-child(2n + 1):after {
				width: 100%;
			}

			article:nth-last-child(1), .posts article:nth-last-child(2) {
				margin-bottom: 0;
			}

			article:nth-last-child(1):before, .posts article:nth-last-child(2):before {
					height: 100%;
				}

			article:nth-last-child(1):after, .posts article:nth-last-child(2):after {
					display: none;
				}

		}

		@media screen and (max-width: 736px) {

			
				margin: 0 0 2em -4.5em;
				width: calc(100% + 4.5em);
			

			article {
					margin: 0 0 4.5em 4.5em;
					width: calc(50% - 4.5em);
				}

			article:before {
						height: calc(100% + 4.5em);
						left: -2.25em;
					}

			article:after {
						bottom: -2.25em;
						width: calc(100% + 4.5em);
					}

			article:nth-last-child(3) {
						margin-bottom: 4.5em;
					}

		}

		@media screen and (max-width: 480px) {

			
				margin: 0 0 2em 0;
				width: 100%;
			

			article {
					margin: 0 0 4.5em 0;
					width: 100%;
				}

			article:before {
						display: none;
					}

			article:after {
						width: 100%;
					}

			article:last-child {
						margin-bottom: 0;
					}

			article:last-child:after {
							display: none;
						}

		}
`

//export default PostList;